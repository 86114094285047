@font-face {
  font-family: "Cal Sans";
  src: url("/fonts/cal.ttf");
}

@font-face {
  font-family: "Inter var";
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: "Regular";
  src: url("/fonts/Inter-roman.var.woff2") format("woff2");
}
